import React from 'react';
// import logo from './logo.svg';
import loader from './assets/images/loader.png';

// import './App.css';

function App() {
  return (
      <div className="App">
        <header className="App-header">
          <img src={loader} className="App-logo" alt="logo"/>
          <div class="content">

            <div class="title m-b-md">Firass</div>

            <div class="links">
              <a href="https://github.com/firassziedan"><i class="fab fa-github"></i> <span>GitHub</span></a>
              <a href="https://www.linkedin.com/in/firassziedan"><i class="fab fa-linkedin-in"></i>
                <span>Linkedin</span></a>
              <a href="https://www.drupal.org/u/firassziedan"><i class="fab fa-drupal"></i><span>Drupal</span></a>
            </div>

          </div>
        </header>
      </div>
  );
}

export default App;
